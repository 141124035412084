import {
  StatuspageComponentSpec,
  StatuspageComponentUpdateSpec,
  StatuspageVisibility,
  UpdateStatuspageSpec,
} from 'src/app/models/statuspage';
import { OffsetPaginationOptions } from '../common/pagination';

export class LoadStatuspagesAction {
  static readonly type = '[Statuspages] Load Statuspages';
}

export class LoadStatuspageDetailsAction {
  static readonly type = '[Statuspages] Load Statuspage';
  constructor(public statuspageId: number) {}
}

export class DeleteStatuspageAction {
  static readonly type = '[Statuspages] Delete Statuspage';
  constructor(public statuspageId: number) {}
}

export class CreateStatuspageAction {
  static readonly type = '[Statuspages] Create Statuspage';
  constructor(
    public spec: {
      name: string;
      visibility: StatuspageVisibility;
    }
  ) {}
}

export class UpdateStatuspageAction {
  static readonly type = '[Statuspages] Update Statuspage';
  constructor(
    public statuspageId: number,
    public updateSpec: UpdateStatuspageSpec
  ) {}
}

export class CreateStatuspageComponentAction {
  static readonly type = '[Statuspages] Create Statuspage Component';
  constructor(
    public statuspageId: number,
    public componentSpec: StatuspageComponentSpec
  ) {}
}

export class UpdateStatuspageComponentAction {
  static readonly type = '[Statuspages] Update Statuspage Component';
  constructor(
    public statuspageId: number,
    public componentId: string,
    public updateSpec: StatuspageComponentUpdateSpec
  ) {}
}

export class RemoveStatuspageComponentAction {
  static readonly type = '[Statuspages] Remove Statuspage Component';
  constructor(
    public statuspageId: number,
    public componentId: string
  ) {}
}

export class OrderStatuspageComponentsAction {
  static readonly type = '[Statuspages] Order Statuspage Components';
  constructor(
    public statuspageId: number,
    public orderedComponentIds: string[]
  ) {}
}

export class LoadSubscriptionsAction {
  static readonly type = '[Statuspages] Load Subscriptions';
  constructor(public pagination: OffsetPaginationOptions) {}
}

export class UnsubscribeFromStatuspageAction {
  static readonly type = '[Statuspages] Unsubscribe from Statuspage';
  constructor(public manageToken: string) {}
}
